
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxValidationRule,

  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import AuditorService from '../services/auditor-service';

import DxButton from 'devextreme-vue/button'



export default {
  components: {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,

    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,

    DxButton
  },
  data() {
    return {
      productos: null,
      dataSourceProducts: null,
      dataSourceBrands: null,
      dataSourceGroupProducts: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Servicios',
        showTitle: true,
        width: 500,
      },
      auditorService: null,
    };
  },
  created() {
    this.auditorService = new AuditorService();

  },
  mounted() {
    this.loadDataInfo();
  },

  methods: {
    loadDataInfo() {
      this.auditorService.getAuditor().then(data => {
        data.forEach(
          element => {

            if (element.bactivo == 1) {
              element.bactivo = true;
            } else {
              element.bactivo = false;
            }

          });
        this.dataSourceProducts = data;

      });
    },
    onSaved() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onEditCanceled() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },

    editRegister(e) {
      let idusuario = 1;
      let vactivo = e.data.bactivo == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pid: e.data.id,
        pnombre: e.data.nombre,
        ppaterno: e.data.paterno,
        pmaterno: e.data.materno,
        pcorreo: e.data.correo,
        ptelefono: e.data.telefono,
        pusuarioregistroid: idusuario,
        pbactivo: vactivo,
      });


      this.auditorService.updateAuditor(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.loadDataInfo();
      });
    },
    deleteRegister(e) {
      let myJSON = JSON.stringify({
        pid: e.data.id,
      });


      this.auditorService.deleteAuditor(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.loadDataInfo();
      });

    },
    createRegister(e) {

      let idusuario = 1;
      let vactivo = e.data.bactivo == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pnombre: e.data.nombre,
        ppaterno: e.data.paterno,
        pmaterno: e.data.materno,
        pcorreo: e.data.correo,
        ptelefono: e.data.telefono,
        pusuarioregistroid: idusuario,
        pbactivo: vactivo,
      });


      this.auditorService.createAuditor(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.loadDataInfo();
      });

    },

    onInitNewRow(e) {
      e.data.serializable = true;
      e.data.bactivo = true;
      //e.data.id_equipo_padre = null;      
      //this.equipmentService.getProductoPadres().then(data => this.dataSourceFatherItems=data);
    },
  },
};
