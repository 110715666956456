import axios from 'axios';

export default class AuditorService {

    getAuditor() {            
        const url = process.env.VUE_APP_APIURL + `/api/auditor`;    
        return axios.get(url).then(res=>res.data);
    }

    updateAuditor(info){
        const url = process.env.VUE_APP_APIURL + `/api/auditoredicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createAuditor(info){
        const url = process.env.VUE_APP_APIURL + `/api/auditoralta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteAuditor(info){
        const url = process.env.VUE_APP_APIURL + `/api/ auditorbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}